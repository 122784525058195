import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const MuzeumWsiStojadlaPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Muzeum Ziemi Mińskiej - 7 Pułk Ułanów Lubelskich" />
      <ReturnToPrevPage to={'/gminy/minsk-mazowiecki'}>
        Materiały dotyczące miasta Mińsk Mazowiecki oraz otaczającej go gminy
      </ReturnToPrevPage>
      <Header1>Muzeum Ziemi Mińskiej - 7 Pułk Ułanów Lubelskich</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <VideoPlayer videoId={896574631} />
        <p>
          Źródła utworzenia tej pierwszej w dziejach Mińska Mazowieckiego
          placówki muzealnej należy szukać w latach osiemdziesiątych
          dwudziestego wieku. Wtedy to kawaler Orderu Wojennego Virtuti Militari
          V klasy, uczestnik kampanii wrześniowej, ppłk. Witold Szaniawski
          przekazał Krzysztofowi Szczypiorskiemu pułkowe pamiątki zgromadzone
          przez istniejące w Londynie koło żołnierzy 7 PUL. Kolekcja,
          systematycznie powiększana przez mińskomazowieckiego literata, malarza
          i szefa lokalnego oddziału „PAX”, okazała się godna zaprezentowania. W
          związku z tym rozpoczął on starania o zrealizowanie tego ambitnego
          zamierzenia. Ostatecznie 29 marca 1992 roku Muzeum 7 Pułku Ułanów
          Lubelskich w Mińsku Mazowieckim rozpoczęło funkcjonowanie w
          drewnianym, zabytkowym i nieistniejącym już, budynku znajdującym się
          pod adresem Warszawska 132.
        </p>
        <p>
          Od lutego 2001 roku zbiory można było oglądać w willi Hubertów.
          Formalnie stanowiły one wówczas placówkę Miejskiego Domu Kultury w
          Mińsku Mazowieckim. Cztery lata później muzeum 7PUL zostało Działem
          Muzeum Ziemi Mińskiej w Mińsku Mazowieckim. Obecnie miejsce
          prezentujące historię ułańskiej jednostki podzielone jest na pięć sal
          wystawowych. Zgodnie z chronologią pułkowych dziejów zwiedzanie działu
          rozpoczyna się od pomieszczenia poświęconego lubelskiemu okresowi
          tworzenia się kawaleryjskiej formacji. Przypada on na lata 1918 –
          1921, które zawierają m.in. udział żołnierzy i oficerów pułku w
          walkach z Ukraińcami oraz w wojnie polsko – bolszewickiej. Wśród
          najważniejszych momentów ówczesnego funkcjonowania 7 PUL można
          wymienić np. szarże pod Berdówką i Cycowem. W pierwszej z muzealnych
          sal można obejrzeć m.in. różne umundurowanie, uzbrojenie i pozostałe
          wyposażenie stosowane w początkowych latach istnienia jednostki. Co
          więcej na ścianach umieszczono portrety jej dowódców i artystyczne
          wyobrażenie walk z udziałem lubelskich ułanów. Drugie z muzealnych
          wnętrz odtwarza wygląd gabinetu dowódcy jednostki z lat 1921 – 1939.
          Wobec tego znalazło się w nim miejsce m.in. na oryginalną kasę
          pancerną z mińskomazowieckich koszar, a także pułkowy sztandar. Na
          ścianach uważne oko zwiedzającego z łatwością dostrzeże dokumentację
          fotograficzną powstałą w czasie kraśnickiej uroczystości nadania
          sztandarowi odznaczenia Orderu Virtuti Militari. 23 maja 1921 roku
          dokonał tego Marszałek Józef Piłsudski.
        </p>
        <p>
          Znajdziemy tu również portret patrona jednostki którym był generał
          Kazimierz Sosnkowski. Całość uzupełniają meble i inne elementy
          wyposażenia miejsca pracy dowódcy 7PUL. W kolejnej z sal
          zaprezentowano natomiast pamiątkowe przedmioty nawiązujące do
          stacjonowania pułku w Mińsku Mazowieckim. Należą do nich np.
          podstawowe elementy ułańskiego wyposażenia i umundurowania, odznaki i
          dokumenty. Zwracają też uwagę rzadko spotykane trofea sportowe
          otrzymywane przez przedstawicieli jednostki za sukcesy osiągane w
          różnych zawodach. Pobyt w tym miejscu ułatwi również zrozumienie roli
          jaką w ówczesnych dziejach miasta odgrywali żołnierze i oficerowie
          pułku. Przybliżenie jego szlaku bojowego związanego z udziałem w
          kampanii wrześniowej stanowi najważniejsze zagadnienie zrealizowane w
          przedostatnim muzealnym pomieszczeniu. Stąd też można w nim zobaczyć
          obrazy wizualizujące militarne starcia 7PUL z niemieckim
          przeciwnikiem.
        </p>
        <p>
          Prawdziwym unikatem, które zaprezentowano w tym miejscu jest żeliwna
          głowa konia. Niegdyś wisiała ona nad pułkową stajnią. Wrażenie na
          osobach odwiedzających muzeum wywiera ponadto przestrzelona kurtka
          mundurowa wspomnianego już podporucznika Witolda Szaniawskiego.
          Przedstawienie historii ułańskiej jednostki znajduje swój finisz we
          wnętrzu poświęconym jej dziejom w okresie okupacji niemieckiej (1939 –
          1945). Umieszczono w nim broń używaną przez żołnierzy konspiracyjnego
          pułku Armii Krajowej „Jeleń”. Jego istnienie stanowiło najlepszy dowód
          kontynuacji funkcjonowania 7PUL w zmienionych wojną warunkach.
          Stacjonujących w okresie międzywojennym XX stulecia żołnierzy i
          oficerów w Mińsku Mazowieckim upamiętniano również w odtworzonym na
          włoskiej ziemi 7 Pułku Ułanów. Wchodził on w skład II Korpusu
          dowodzonego przez generała Władysława Andersa. Wyposażenie i
          umundurowanie jednostki powstałej na Półwyspie Apenińskim jest też
          udostępnione do oglądania w tej piątej sali wystawowej Działu 7 Pułku
          Ułanów Lubelskich Muzeum Ziemi Mińskiej w Mińsku Mazowieckim.
        </p>
        <p>
          Zauważmy, że odbywają się w niej też różne wydarzenia kulturalne, w
          tym koncerty muzyki klasycznej. Osoby odwiedzające placówkę mieszczącą
          się w willi Hubertów mogą ponadto poznać dzieje jednej ze
          współczesnych jednostek wojskowych stacjonujących w Mińsku
          Mazowieckim. Służy temu sala pamięci 2 Mińskomazowieckiej Brygady
          Obrony Terytorialnej im. gen. Franciszka Kleeberga. Stacjonowała ona w
          mieście nad Srebrną w latach 1995 – 2005. Wśród znajdujących się w tym
          miejscu eksponatów można wymienić m.in. elementy umundurowania i
          wyposażenia żołnierzy i oficerów, a także okolicznościowe trofea
          sportowe. W tym fragmencie muzealnego działu znalazło się również
          miejsce na zaprezentowanie początków funkcjonowania Szwadronu
          Reprezentacyjnego Wojska Polskiego. Dodajmy na zakończenie, że
          siedziba Działu 7 Pułku Ułanów Lubelskich Muzeum Ziemi Mińskiej w
          Mińsku Mazowieckim znajduje się w jednym z najstarszych, murowanych,
          budynków istniejących na terenie miasta. Zgodnie z ustaleniami
          regionalistów Willa Hubertów została zbudowana pod koniec lat 70 –
          tych XIX wieku.
        </p>
        <p>
          Pierwszym jej właścicielem był Kazimierz Eppler, następnym Aleksander
          Remi. Od drugiego z wymienionych posiadaczy obiekt odkupił, w 1908
          roku, lekarz zakładowy w fabryce Rudzkiego Jan Hubert. Co ciekawe w
          budynku swoją przystań znalazła biblioteka oddziału Polskiej Macierzy
          Szkolnej. W okresie okupacji niemieckiej w Willi Hubertów miały
          miejsce odprawy prowadzone przez komendanta obwodu ZWZ/AK „Mewa”,
          „Jamnik”, „Kamień” porucznika Ludwika Wolańskiego „Lubicza”. 14
          sierpnia 1945 roku właścicielką posesji została żona Jana Huberta
          Anna. Wyjaśnijmy, że jej męża aresztowano w ramach Wielkiej Wsypy i
          przewieziono na Pawiak. Z więzienia tego jeden z najwybitniejszych
          mińszczan już nie wrócił. W 1948 roku wdowa przekazała budynek wraz z
          przyległościami na cele charytatywne. Stał się on odtąd własnością
          mińskiego Oddziału Polskiego Czerwonego Krzyża, zaś Anna Hubert
          zapewniła sobie dożywotnie prawo do przebywania na terenie
          nieruchomości. W 1980 roku wspomniana organizacja pozarządowa oddała
          ją na rzecz Skarbu Państwa. Wobec tego w obiekcie zlokalizowano
          mieszkania kwaterunkowe. Tym samym piękna niegdyś willa zaczęła
          niszczeć.
        </p>
        <p>
          Początek powrotu nieruchomości do dawnego blasku nastąpił w latach 90
          – tych ubiegłego stulecia. Przeprowadzane remonty pozwoliły ponownie
          ożywić to miejsce. Poza samym muzealnym budynkiem uwagę osób
          odwiedzających to miejsce zwraca jego uporządkowany, zadbany i
          gustownie zaprojektowany ogród.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/minsk-mazowiecki/mzm7pul/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/minsk-mazowiecki/mzm7pul/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default MuzeumWsiStojadlaPage
